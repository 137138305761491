import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import PageTitle from '../../components/main/PageTitle';
import getImagePath from '../../../utils/getImagePath';
import { API_PATH } from '../../../Variables';
import EkspresImg from '../../images/70_6_ ekspres2.png';
import Karta150Img from '../../images/30_12_karta150.png';
import KuchenkaImg from '../../images/70_7_ kuchenka2.png';
import Karta450Img from '../../images/70_8_ karta3502.png';
import axios from 'axios';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate(); 
  const [award, setAward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1); 
  const [points, setPoints] = useState(0); 
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchAwardById = async (id) => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_PATH}/awards/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = await response.json();
        setAward(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching award:', error);
        setLoading(false);
      }
    };

    fetchAwardById(id);
  }, [id]);

  useEffect(() => {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    const userId = user?.id;
  
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_PATH}/user/${userId}/points`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setPoints(response.data.points);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };
  
    fetchPoints();
  }, []); 

  const getGlobalImagePath = (imgName, defaultImage) => {
    if (imgName === 'Ekspres DELONGHI Dolce Gusto Mini Me') {
      return EkspresImg;
    } if (imgName === 'Karta przedpłacona 150 zł') {
      return Karta150Img;
    } if (imgName === 'Kuchenka mikrofalowa AMICA AMGF20M1W') {
      return KuchenkaImg;
    } if (imgName === 'Karta przedpłacona Pluxee 350 zł') {
      return Karta450Img;
    } else {
      return defaultImage;
    }
  }

  const handleOrder = () => {
    if (points < award.points * quantity) {
      setModalIsOpen(true);
      return;
    }
    navigate(`/summary?id=${id}&quantity=${quantity}&points=${award.points}`);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!award) {
    return <p>Award not found.</p>;
  }

  return (
    <>
      <Header />
      <section className='award-details'>
        <PageTitle title={`${award.name}`} />
        <div className='awards-page__information'>zamawianie nagród od 21.10.2024</div>
        <div className='award-details__inner'>
          <div className='award-details__points'><strong>od {award.group.replace('pkt', '')} pkt</strong></div>
          <div className='award-details__title'>{award.group_name}</div>
          <div className='award-details__wrapper'>
            <div className='award-details__column1'>
              <img src={getGlobalImagePath(award.name, getImagePath(award.img_name))} alt={award.name} className='award-details__image' />
            </div>
            <div className='award-details__column2'>
              <div className='award-details__column2_title'>{award.name}</div>
              <div className='award-details__column2_price'>{award.points} pkt</div>
              <div className='award-details__column2_wrapper'>
                <div className='award-details__column2_count'>
                  <button className='award-details__subtract' onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</button>
                  <p className='award-details__counter'>{quantity}</p>
                  <button className='award-details__add' onClick={() => setQuantity(quantity + 1)}>+</button>
                </div>
                {/* onClick={handleOrder} */}
                <button className='award-details__order' onClick={handleOrder}>Zamawiam</button>
              </div>
              <p className='award-details__column2_description_button'>zobacz szczegóły <div className='triangle'></div></p>
              <p className='award-details__column2_description' dangerouslySetInnerHTML={{ __html: award.desc.replace(/\\n/g, '<br><br>') }}></p>
              <div className='awards-page__warning awards-page__warning--award'>
                <div className='awards-page__warning_icon awards-page__warning_icon--award'>!</div>
                <p>W przypadku niedostępności produktu zaproponujemy model o podobnych parametrach.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Insufficient Points"
        className="Modal"
        overlayClassName="Overlay"
      >
        <p><strong>Masz za mało punktów, aby zamówić tę nagrodę.</strong></p>
        <button onClick={closeModal}>ok</button>
      </Modal>
    </>
  );
};

export default Home;